<template>
  <v-layout row wrap class="ma-0 flex-none headline grey--text text--darken-2" align-center>
    <booking-date v-if="bookings.read" :booking="bookings.read" />
    <v-chip class="subtitle-1 mr-2 my-1" label>
      <v-icon class="mr-2">{{ isParkBooking(bookings.read) ? 'mdi-ticket' : 'mdi-bed' }}</v-icon>
      <span>{{ isParkBooking(bookings.read) ? $t('bookings.park_visitor') : $t('bookings.hotel_guest')}}</span>
    </v-chip>
    <v-chip v-if="pricingGroupName" label class="subtitle-1 mr-2 my-1">{{ pricingGroupName }}</v-chip>
    <v-chip v-if="isBookingCancelled" color="red lighten-3 my-1" class="subtitle-1" label>{{ $t('bookings.cancelled') }}</v-chip>
  </v-layout>
</template>

<script>
import dayjs from 'dayjs'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'BookingInfo',
  computed: {
    ...mapState(['bookings']),
    ...mapGetters({
      isBookingCancelled: 'bookings/isBookingCancelled',
      isParkBooking: 'configuration/isParkBooking',
      pricingGroupTypeGetter: 'configuration/pricingGroupType',
      isBookingOnline: 'orders/someNickelodeonAppServerOrder',
      bookingPricingGroup: 'bookings/bookingPricingGroup'
    }),
    pricingGroupName () {
      if (this.bookingPricingGroup) {
        const pricingGroupType = this.pricingGroupTypeGetter(this.bookingPricingGroup)
        return pricingGroupType
          ? pricingGroupType.charAt(0) + pricingGroupType.slice(1).toLowerCase().replace('_', ' ')
          : ''
      }
    }
  },
  components: {
    BookingDate: () => import('@/components/view-components/bookings/BookingDate.vue')
  }
}
</script>
